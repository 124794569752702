import React from 'react';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';

import Select from '../../components/select/select';
import { SpaceRow } from '../../components/space/space';
import { getUserDetails, updateUserDetails } from './settings.api';
import RenewPassword from '../../components/renew_password/renew_password';
import { getUserNameTitles } from '../dashboard/propects_details/prospectDetails.api';

export default function TabContentUserinfo() {
  const [optionsTitle, setOptionsTitle] = React.useState([]);

  const [isFetching, setIsFetching] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [passwordState, setPasswordState] = React.useState(false);
  const [renewPasswordModalState, setRenewPasswordModalState] = React.useState(false);
  const options = [{
    lable: 'User',
    value: '5',
  }, {
    lable: 'Admin',
    value: '1',
  }];
  React.useEffect(() => {
    getUserDetails(setIsFetching, setUser);
    getUserNameTitles(setOptionsTitle);
  }, []);
  const handleFieldEdit = (fieldkey, value) => {
    const newUserData = { ...user };
    newUserData[fieldkey] = value;

    setUser(newUserData);
    console.log("handleFieldEdit ",user.type)
  };

  const handleTypeEdit = (fieldkey, value) => {
    user["type"] = [{id:value}];
    // console.log("handleTypeEdit ",value)
  };
  const handleFormUpdate = () => {


    // console.log("handleFormUpdate",get(user, 'type'))
    if (get(user, 'id')) {
      const payload = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        title_id: user.title_id,
        type: user.type,
      };
      console.log("handleFormUpdate",payload)
      updateUserDetails(setIsFetching, payload)
        .then(() => {
          getUserDetails(setIsFetching, setUser);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <div className="profile-details-container">
      <RenewPassword
        isOpen={renewPasswordModalState}
        toggleVisibility={setRenewPasswordModalState}
      />
      <Grid container>
        <Grid container item sm={6} md={1}>
          <p
            className="main-font-regular font-size-14 font-color-grey"
          >
            Aanspreking
          </p>
        </Grid>
        <Grid container item sm={6} md={10} className="padding-left-50">
          <Select
            options={optionsTitle}
            onBlur={handleFormUpdate}
            selected={user?.title?.id}
            onChange={(event) => handleFieldEdit('title_id', event.target.value)}
          />
        </Grid>
      </Grid>
      <SpaceRow top="15" />
      <Grid container>
        <Grid container item sm={6} md={1}>
          <p className="main-font-regular font-size-14 font-color-grey">Naam</p>
        </Grid>
        <Grid container item sm={6} md={10} className="padding-left-50">
          <input
            className="main-font-semi-bold font-size-14 font-color-black cursor-pointer profile-editable"
            value={user?.firstName || ''}
            onChange={(event) => handleFieldEdit('firstName', event.target.value)}
            onBlur={handleFormUpdate}
          />
        </Grid>
      </Grid>
      <SpaceRow top="15" />
      <Grid container>
        <Grid container item sm={6} md={1}>
          <p
            className="main-font-regular font-size-14 font-color-grey"
          >
            Voornaam
          </p>
        </Grid>
        <Grid container item sm={6} md={10} className="padding-left-50">
          <input
            className="main-font-semi-bold font-size-14 font-color-black cursor-pointer profile-editable"
            value={user?.lastName || ''}
            onChange={(event) => handleFieldEdit('lastName', event.target.value)}
            onBlur={handleFormUpdate}
          />
        </Grid>
      </Grid>
      <SpaceRow top="15" />
      <Grid container>
        <Grid container item sm={6} md={1}>
          <p
            className="main-font-regular font-size-14 font-color-grey"
          >
            Email
          </p>
        </Grid>
        <Grid container item sm={6} md={10} className="padding-left-50">
          <input
            className="main-font-semi-bold font-size-14 font-color-black cursor-pointer profile-editable"
            value={user?.email || ''}
            onChange={(event) => handleFieldEdit('email', event.target.value)}
            onBlur={handleFormUpdate}
          />
        </Grid>
      </Grid>
      <SpaceRow top="15" />
      <Grid container>
        <Grid container item sm={6} md={1}>
          <p
            className="main-font-regular font-size-14 font-color-grey"
          >
            Telefoon
          </p>
        </Grid>
        <Grid container item sm={6} md={10} className="padding-left-50">
          <input
            className="main-font-semi-bold font-size-14 font-color-black cursor-pointer profile-editable"
            value={user?.phone || ''}
            onChange={(event) => handleFieldEdit('phone', event.target.value)}
            onBlur={handleFormUpdate}
          />
        </Grid>
      </Grid>
      <SpaceRow top="15" />
      <Grid container>
        <Grid container item sm={6} md={1}>
          <p
            className="main-font-regular font-size-14 font-color-grey"
          >
            Wachtwoord
          </p>
        </Grid>
        <Grid container item sm={6} md={10} className="padding-left-50">
          <div className="profile-details-password-container">
            <input
              type={`${passwordState ? 'text' : 'password'}`}
              disabled
              className=" main-font-semi-bold font-size-14 font-color-black profile-details-password"
              value="1234567"
            />
            <button
              type="button"
              className="
          profile-details-password-renew
          center-content
          flex-shrink-disable animatable-400
           ease-in-out-quart c
           ursor-pointer"
              onClick={() => setRenewPasswordModalState(true)}
            >
              <p
                className=" main-font-semi-bold font-size-14 font-color-blue-grey animatable-400 ease-in-out-quart"
              >
                Wijzigen
              </p>
            </button>
          </div>
        </Grid>
      </Grid>
      <SpaceRow top="15" />
      <Grid container>
        <Grid container item sm={6} md={1}>
          <p
            className="main-font-regular font-size-14 font-color-grey"
          >
            Status
          </p>
        </Grid>
        <Grid container item sm={6} md={10} className="padding-left-50">

          <Select
            options={options}
            selected={user?.type && user?.type[0] && user?.type[0]?.id}
            value={user?.type && user?.type[0] && user?.type[0]?.id}
            onChange={(event) => handleTypeEdit('type', event.target.value)}
            onBlur={handleFormUpdate}
          />
        </Grid>
      </Grid>
    </div>
  );
}
